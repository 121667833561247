<template lang="html">
  <section class="contact-section" id="s-contact">
    <div class="container oversized-container">
    <div class="row">
      <div class="col-lg-6 offset-lg-6 col-form">
        <div class="box-titles">
          <h2 class="title-s1">{{ $t("pages.contact.titles.contact") }}</h2>
          <h2 class="title-s2">{{ $t("pages.contact.titles.contactUs") }}</h2>
          <p class="txt-email">
            <span>support@lion-ward.com</span>
          </p>
        </div>

        <div class="box-form">
          <b-form @submit="onSubmit">
            <b-form-group class="custom-f-group-s1"
              :label="$t('pages.contact.form.name')">
              <b-form-input
                v-model="form.name"
                type="text"
                :placeholder="$t('pages.contact.form.nameLabel')"
                size="lg"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group class="custom-f-group-s1"
              :label="$t('pages.contact.form.email')">
              <b-form-input
                v-model="form.email"
                type="email"
                :placeholder="$t('pages.contact.form.emailLabel')"
                size="lg"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group class="custom-f-group-s1"
              :label="$t('pages.contact.form.message')">
              <b-form-textarea
                v-model="form.msg"
                :placeholder="$t('pages.contact.form.messageLabel')"
                rows="6"
                max-rows="6"
                size="lg"
              ></b-form-textarea>
            </b-form-group>

            <p class="mt-4 pt-2 text-right">
              <b-button type="submit" class="btn-s1">{{ $t('pages.contact.form.btnSendMsg') }}</b-button>
            </p>
          </b-form>
        </div>
      </div>
    </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: null,
        email: null,
        msg: null,
      },
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-bottom-right',
        appendToast: true
      })
    },

    onSubmit(event) {
      event.preventDefault();

      var params = this.form;
      var apiURL = tools.url("/api/contact");

      axios.post( apiURL,params )
      .then( (response) => {
        // alert(response.data.response);
        this.makeToast('success', response.data.response, 'Mensaje enviado');
        // alert(response.data.response);

        for (let prop in this.form) {
          this.form[prop] = '';
        }
      })
      .catch( (error) => {
        // console.log(error.response.data);
        let errors = error.response.data;

        for (let prop in errors) {
          // console.log(errors[prop]);
          this.makeToast('danger', errors[prop], '¡Error!');
        }
      });
    },
  }
}
</script>
