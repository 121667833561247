<template lang="html">
  <div id="about-us-page">

    <section class="main-section">
      <div class="box-content">
        <div class="container oversized-container">
          <div class="row">
            <div class="col-lg-6 col-titles">
              <!-- <h5 class="title-s1">{{ $t("pages.aboutUs.titles.us") }}</h5> -->
              <h5 class="title-s2">{{ $t("pages.aboutUs.titles.aboutLionward") }} <img class="logo" src="public/images/logo-txt-noslogan.svg" /></h5>
            </div>

            <div class="col-lg-6 col-info">
              <p>
                {{ $t("pages.aboutUs.texts.content2") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="info-section">
      <div class="container">
        <h3 class="mb-1 title-1">{{ $t("pages.aboutUs.titles.mission") }}</h3>
        <p class="p-lg">
          {{ $t("pages.aboutUs.texts.mission") }}
        </p>

        <h3 class="mt-4 mb-1 title-1">{{ $t("pages.aboutUs.titles.vision") }}</h3>
        <p class="p-lg">
          {{ $t("pages.aboutUs.texts.vision") }}
        </p>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
