<template lang="html">
  <div id="text-page">
    <div class="container">
      <h1 class="mb-2 page-title">{{ $t("pages.nofPrivacy.title") }}</h1>

      <!-- Esp -->
      <div v-if="$i18n.locale == 'es'">
        <p class="text-center f-w-700">
          Fecha de actualización: Noviembre de 2023.
        </p>

        <br />
        <h5>Quiénes somos y en dónde encontrarnos.</h5>
        <p>
          En cumplimiento de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento (en adelante la “Ley”), FICUNIAM, S.A.P.I DE C.V. SOFOM E.N.R., (en adelante “LIONWARD”), con domicilio para oír y recibir todo tipo de documentos y notificaciones en Calle 18 #451, Sol Campestre, Mérida, Yucatán, México, C.P. 97113; como Responsable del Tratamiento de los Datos Personales que recaba, pone a tu disposición el presente Aviso de Privacidad Integral (en adelante el “Aviso de Privacidad”). <br />
          <br />
          El Tratamiento de tus Datos Personales se llevará a cabo de conformidad con los siguientes términos:
        </p>

        <br />
        <h5>Datos Personales que recabamos.</h5>
        <ul>
          <li>
            Datos de identificación: Nombre, Identificación oficial, Registro Federal de Contribuyentes (RFC), CURP, domicilio, correo electrónico, nacionalidad, edad, estado civil, ocupación.
          </li>
          <li>
            Datos de contacto: Comprobante de domicilio, domicilio de entrega, correo electrónico fijo y teléfono celular.
          </li>
        </ul>
        <p>
          Dentro de las categorías de Datos Personales no existen Datos Personales sensibles, entendiéndose por estos, aquellos que afecten a la esfera más íntima del Titular, o cuya utilización indebida pueda dar origen a discriminación o conlleve un riesgo grave para éste.
        </p>

        <br />
        <h5>Datos Personales Financieros o Patrimoniales que solicitamos.</h5>
        <ul>
          <li>
            Historial crediticio: Antecedentes de Crédito obtenidos a través de Sociedades de Información Crediticia con medidas de protección de Datos Personales. 
          </li>
        </ul>

        <br />
        <h5>Finalidades.</h5>
        <p>
          En términos de lo establecido por la Ley y su Reglamento, los Datos personales que nos proporciones serán utilizados para las siguientes finalidades:
        </p>
        <br />
        <h6>Finalidades Primarias.</h6>
        <p>
          Necesarias para la prestación del servicio fiduciario y cumplimiento de obligaciones derivadas de una relación jurídica.
        </p>
        <ul>
          <li>
            Realizar trámites, procedimientos y gestiones para la adquisición o contratación de productos y/o servicios para nuestros clientes, usuarios y clientes potenciales (prospectos).
          </li>
          <li>
            Proveer los servicios y productos que contrate con LIONWARD.
          </li>
          <li>
            Cumplir con las obligaciones derivadas de la relación jurídica, relativas a productos y/o servicios que se adquieran.
          </li>
          <li>
            Consultar historial crediticio ante Sociedades de Información Crediticia (Círculo de Crédito).
          </li>
          <li>
            Informar sobre actualizaciones o cambios en productos y/o servicios contratados.
          </li>
          <li>
            Brindar asesoría, procesar solicitudes, realizar actividades de cobranza, facturación y aclaraciones.
          </li>
          <li>
            Cumplir con leyes, reglamentos y demás disposiciones legales aplicables y vigentes, así como atender los requerimientos de las autoridades que correspondan.
          </li>
          <li>
            Dar seguimiento a nuestra relación comercial para atender requerimientos.
          </li>
          <li>
            Mantener actualizados los registros en nuestros sistemas para responder a consultas.
          </li>
        </ul>

        <br />
        <h6>Finalidades Secundarias.</h6>
        <p>
          No necesarias para la prestación del servicios fiduciarios o cumplimiento de obligaciones que hayan dado origen a la relación jurídica con el Responsable:
        </p>
        <ul>
          <li>
            Realizar actividades de mercadotecnia, publicidad y prospección comercial diversas a los productos y servicios que brinda LIONWARD.
          </li>
          <li>
            Realizar estudios internos sobre hábitos de consumo o sobre el desarrollo de nuevos productos, evaluar la calidad de productos y/o servicios de LIONWARD, así como actividades de mercadotecnia, publicidad y prospección comercial relacionada con los productos y/o servicios de LIONWARD.
          </li>
        </ul>

        <br />
        <h6>Finalidades de Marketing</h6>
        <p>
          Utilizamos sus datos personales para enviarle correos electrónicos u otra información referente a nuestros servicios actualizados con la finalidad de obtener más ventas. Esta información podría ser promociones, ofertas, encuestas, entre otras.
        </p>

        <br />
        <h6></h6>
        <h5>Medidas para el resguardo, uso o divulgación de tus Datos.</h5>
        <p>
          Tus Datos serán resguardados bajo medidas de seguridad administrativas, electrónicas y técnicas, las cuales han sido implementadas, en términos de la Ley, con el objeto de proteger tus Datos contra cualquier daño, pérdida, alteración, destrucción o el uso, acceso y tratamiento no autorizado, así como acotar cualquier riesgo que pudiera llegar a presentarse.
        </p>
        <p>
          LIONWARD se compromete a proteger la seguridad de los datos personales que nos proporciona, cumplimentando todos los actos jurídicos que se requieran, de igual manera protegemos que no exista un uso indebido o divulgación sin autorización de la información personal y las medidas administrativas correspondientes en nuestra empresa. <br />
          <br />
          Contamos con medidas de seguridad que incluyen acceso restringido a sus archivos y monitoreo constante de nuestra base de datos. En caso de que se presenten vulneraciones de seguridad ocurridas en cualquier fase del tratamiento, les informaremos al correo electrónico que nos fuera proporcionado, para que tome las medidas adecuadas para su protección.<br />
          <br />
          Además, contamos con políticas internas para la gestión y tratamiento de los datos personales, considerando el contexto en el que se encuentran y el ciclo de vida de los datos personales, es decir, su obtención, uso y posterior supresión.<br />
          <br />
          Identificamos el riesgo de los datos personales, considerando las amenazas y vulnerabilidades existentes para los datos personales que nos proporcionan, verificando que los recursos involucrados en su tratamiento cuenten con la seguridad suficiente.
        </p>

        <br />
        <h5>Cómo ejercer tus Derechos ARCO.</h5>
        <p>
          Cualquier Titular o su representante legal podrá ejercer sus Derechos ARCO (Acceso, Rectificación, Cancelación y Oposición). Así mismo podrás ejercer tu derecho a revocar el consentimiento para el Tratamiento de tus Datos (siempre y cuando no existan derechos vigentes en favor de LIONWARD o una disposición legal que lo impida). <br />
          <br />
          Para ello, LIONWARD te ofrece la opción de enviar tu solicitud de ejercicio de Derechos ARCO o revocación del consentimiento vía correo electrónico a la siguiente dirección: privacy@lion-ward.com, adjuntando la documentación que acredite tu identidad como Titular de los Datos (copia simple en formato electrónico de tu credencial de elector, pasaporte vigente, cédula profesional o documento de identificación migratoria en caso de personas extranjeras) o, en su caso, acreditar la representación legal del Titular con copia simple en formato electrónico de la carta poder que deberá contar con firma autógrafa del Titular y/o el representante legal, adjuntando copias simples de sus identificaciones oficiales.<br />
          <br />
          LIONWARD te comunicará la respuesta de tu solicitud de ejercicio de Derechos ARCO o revocación de consentimiento (en adelante Solicitud) a la dirección de correo electrónico por medio de la cual enviaste tu solicitud.<br />
          <br />
          Una vez que dicha Solicitud sea recibida por LIONWARD se te comunicará sobre la procedencia o improcedencia de tu Solicitud en un plazo no mayor a 20 (veinte) días hábiles, contados a partir de la fecha de recepción de la misma. En caso de que tu Solicitud resulte procedente, LIONWARD tendrá un plazo de 15 (quince) días hábiles adicionales para hacerla efectiva. Cuando así lo justifiquen las circunstancias, los plazos anteriores podrán ser ampliados por una sola ocasión por un período igual.<br />
          <br />
          En el caso de que la información proporcionada en la Solicitud sea insuficiente o errónea para ser atendida, LIONWARD dentro de los 5 (cinco) días hábiles siguientes a la recepción de la Solicitud, podrá solicitarte que aportes información o documentación adicional para acreditar tu Solicitud y contarás con 10 (diez) días hábiles para atender el requerimiento, contados a partir del día siguiente en que hayas sido notificado por correo electrónico. De no dar respuesta en dicho plazo, LIONWARD podrá negar al solicitante el ejercicio de cualquiera de los Derechos ARCO o la revocación del consentimiento.<br />
          <br />
          En caso de que el Titular atienda el requerimiento de información, el plazo para que LIONWARD dé respuesta a la Solicitud, empezará a correr al día siguiente hábil a aquel en que el Titular haya atendido el requerimiento.<br />
          <br />
          Si tienes alguna duda adicional o inquietud, nos puedes contactar enviando un correo electrónico al: privacy@lion-ward.com.
        </p>

        <br />
        <h5>Opciones y medios para limitación de datos.</h5>
        <p>
          Con el objeto de que usted pueda limitar el uso y divulgación de su información, se ponen a disposición los siguientes medios:<br />
          <br />
          Su inscripción en el Registro Público para Evitar Publicidad (REPEP), que está a cargo de la Procuraduría Federal del Consumidor (PROFECO), con la finalidad de no recibir publicidad vía telefónica de proveedores de sectores de: i) telecomunicaciones, ii) turismo y iii) comercial, ni que su información sea utilizada con fines mercadotécnicos o publicitarios. Para mayor información sobre este registro, usted puede consultar el portal de internet de la PROFECO www.profeco.gob.mx o bien ponerse en contacto directo con ésta.
        </p>

        <br />
        <h5>Modificaciones al aviso de privacidad</h5>
        <p>
          El presente aviso puede sufrir modificaciones, cambios o actualizaciones en cualquier momento, derivado de nuevos requerimientos legales y/o de nuestras propias necesidades por el servicio que ofrecemos, en cuyo caso se hará de su conocimiento.<br />
          <br />
          El procedimiento a través del cual se llevarán a cabo las notificaciones sobre modificaciones, cambios o actualizaciones al presente aviso de privacidad será a través del correo electrónico que nos haya proporcionado.
        </p>
      </div>
      <!--  -->

      <!-- Eng -->
      <div v-if="$i18n.locale == 'en'">
        <p class="text-center f-w-700">
          Date of update: November 2023.
        </p>

        <br />
        <h5>Who we are and where to find us.</h5>
        <p>
          In compliance with “Ley Federal de Protección de Datos Personales en Posesión de los Particulares” (in English “Federal Law on the Protection of Personal Data in Possession of Private Parties”) and its Regulations (hereinafter the "Law"), FICUNIAM, S.A.P.I DE C.V. SOFOM E.N.R., (hereinafter "LIONWARD with address for service of process at  Calle 18 #451, Sol Campestre, Mérida, Yucatán, México, C.P. 97113; as Controller of the Personal Data collected, as Controller of the Personal Data collected, provides hereby the Privacy Notice (hereinafter the "Privacy Notice").<br />
          <br />
          The Treatment of your Personal Data will be conducted in accordance with the following terms:
        </p>

        <br />
        <h5>Personal Data collected by us.</h5>
        <ul>
          <li>
            Identification Data: Name, Official ID, Federal Taxpayers Registry (RFC), CURP, address, email, nationality, age, marital status, occupation.
          </li>
          <li>
            Contact information: Proof of address, delivery address, email address and cell phone number.
          </li>
        </ul>
        <p>
          Within the categories of Personal Data, there are no sensitive Personal Data, meaning those that affect the most intimate sphere of the Data Owner, or whose improper use may give rise to discrimination or entail a serious risk for the Data Owner.
        </p>

        <br />
        <h5>Financial or Property Personal Data requested by us.</h5>
        <ul>
          <li>
            Credit history: Credit history obtained through Credit Information Companies with Personal Data protection measures.
          </li>
        </ul>

        <br />
        <h5>Purposes.</h5>
        <p>
          In terms of the provisions of the Law and its Regulations, your Personal Data will be used for the following purposes:
        </p>
        <br />
        <h6>Primary Purposes.</h6>
        <p>
          Required for the provision of the trust services and compliance with the obligations arising from a legal relationship.
        </p>
        <ul>
          <li>
            Perform administrative procedures and formalities for the acquisition or contracting of products and / or services for our customers, users and potential customers (prospects).
          </li>
          <li>
            To provide the services and products contracted with LIONWARD.
          </li>
          <li>
            Comply with the obligations arising from the legal relationship, relating to products and / or services that are acquired.
          </li>
          <li>
            Consult credit history before Credit Information Societies ("Círculo de Crédito").
          </li>
          <li>
            Inform about updates or changes in products and/or services contracted.
          </li>
          <li>
            Provide advice, process applications, perform collection activities, billing and clarifications.
          </li>
          <li>
            Comply with laws, regulations and other applicable and current legal provisions, as well as meet the requirements of the corresponding authorities.
          </li>
          <li>
            Follow up on our business relationship to meet requirements.
          </li>
          <li>
            Maintain updated records in our systems to respond to inquiries.
          </li>
        </ul>

        <br />
        <h6>Secondary Purposes.</h6>
        <p>
          Not necessary for the provision of trust services or compliance with obligations that originated the legal relationship with the Controller:
        </p>
        <ul>
          <li>
            Perform marketing, advertising, and commercial prospecting activities other than the products and services provided by LIONWARD.
          </li>
          <li>
            Conduct internal studies on consumer habits or on the development of new products, evaluate the quality of products and/or services of LIONWARD, as well as marketing activities, advertising and commercial prospecting related to LIONWARD's products and/or services.
          </li>
        </ul>

        <br />
        <h6>Marketing Purposes</h6>
        <p>
          We use your personal data to send you e-mails or other information regarding our updated services in order to obtain more sales. This information could be promotions, offers, surveys, among others.
        </p>

        <br />
        <h5>Security measures for the guard, use or disclosure of your Data.</h5>
        <p>
          Security measures for the guard, use or disclosure of your Data.<br />
          <br />
          Your Data will be secured under administrative, electronic, and technical security measures, which have been implemented, in terms of the Law, to protect your Data against any damage, loss, alteration, destruction or unauthorized use, access and treatment, as well as to limit any risk that may arise.<br />
          <br />
          LIONWARD is committed to protect the security of the personal information you provide us, fulfilling all the legal acts that are required, in the same way we protect that there is no misuse or unauthorized disclosure of personal information and the corresponding administrative measures in our company.<br />
          <br />
          We have security measures that include restricted access to your files and constant monitoring of our database. In case of security breaches occurring at any stage of processing, we will inform you by e-mail provided to us, so that you can take appropriate measures for your protection.<br />
          <br />
          In addition, we have internal policies for the management and processing of personal data, considering the context in which they are found and the life cycle of personal data, meaning its collection, use and subsequent deletion.<br />
          <br />
          We identify the risk of personal data, considering the existing threats and vulnerabilities for the personal data provided to us, verifying that the resources involved in their treatment have sufficient security.
        </p>

        <br />
        <h5>How to exercise your ARCO Rights.</h5>
        <p>
          Any Data Owner or their legal representative may exercise their ARCO Rights (Access, Correction, Cancellation and Objection). Likewise, you may exercise your right to revoke your consent to the Treatment of your Data (as long as there are no existing rights in favor of LIONWARD or a legal provision that prevents it).<br />
          <br />
          To do so, LIONWARD offers you the possibility to send your request to exercise your ARCO Rights or revoke your consent via email to the following address: privacy@lion-ward.com, attaching the documentation proving your identity as the Data Owner (single copy in digital or electronic format of your voting credential, valid passport, Professional ID or immigration document in the case of foreigners) or, if applicable, prove the legal representation of the Data Owner with a single copy in digital or electronic format of the power of attorney that must have the signature of the Data Owner and/or the legal representative, attaching simple copies of their official IDs.<br />
          <br />
          LIONWARD will communicate the response to your request to exercise your ARCO Rights or revoke consent (hereinafter Request) to the email address through which you sent your request.<br />
          <br />
          Once the Request is received by LIONWARD, you will be informed of the acceptance or rejection of your Request within a period not exceeding 20 (twenty) business days from the date of receipt of the Request. If your Request is granted, LIONWARD will have an additional 15 (fifteen) business days to make it effective. When justified by the circumstances, the above deadlines may be extended once for an equal period.<br />
          <br />
          If the information provided in the Request is insufficient or erroneous to be met, LIONWARD within 5 (five) business days following receipt of the Request, may ask you to provide additional information or documentation to substantiate your Request and you will have 10 (ten) business days to meet the requirement, counted from the day after you have been notified by email. Failure to respond within that period, LIONWARD may deny the applicant the exercise of any of the ARCO Rights or the revocation of consent.<br />
          <br />
          If the Data Owner complies with the request for information, the deadline for LIONWARD to respond to the Request will begin to run on the next business day following the day on which the Data Owner has complied with the request.<br />
          <br />
          If you have any additional questions or concerns, you can contact us by sending an email to: privacy@lion-ward.com.
        </p>

        <br />
        <h5>Options and means for data limitation</h5>
        <p>
          In order for you to limit the use and disclosure of your information, the following means are available: <br />
          <br />
          Your registration in the Public Registry to Avoid Advertising (REPEP), which is in charge of the Federal Consumer Protection Agency (PROFECO), with the purpose of not receiving advertising via telephone from suppliers of: i) telecommunications, ii) tourism and iii) commercial sectors, nor that your information is used for marketing or advertising purposes. For more information about this registration, you can consult the PROFECO's website www.profeco.gob.mx or contact it directly.
        </p>

        <br />
        <h5>Modifications to the privacy notice</h5>
        <p>
          This notice may suffer modifications, changes or updates at any time, derived from new legal requirements and/or our own needs for the service we offer, in which case we will inform you.<br />
          <br />
          The procedure through which the notifications about modifications, changes or updates to this privacy notice will be carried out through the e-mail address you have provided us.
        </p>
      </div>
      <!--  -->
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
    }
  },
}
</script>
