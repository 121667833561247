<template lang="html">
  <footer id="footer">
		<div class="container oversized-container">
			<div class="row">
				<div class="col-lg-12 col-footer col-logo">
					<!-- <router-link to="/">
						<img src="public/images/logo-white.svg">
					</router-link> -->

					<h5 class="who-title">{{ $t("shared.texts.regulation") }}</h5>
					<p class="regulators">
            <img class="dk" src="public/images/shared/confusef-cnbv-.png">
						<img class="mb" src="public/images/shared/confusef-cnbv.png">
					</p>
				</div>

				<div class="col-sm-6 col-lg-6 col-footer">
					<p>
						<router-link to="/#s-about" v-scroll-to="'#s-about'">{{ $t("shared.links.aboutUs") }}</router-link>
					</p>
					<p class="mt-1 mt-lg-3">
						<router-link to="/servicios">{{ $t("shared.links.services") }}</router-link>
					</p>
          <p class="mt-1 mt-lg-3">
						<router-link to="/faqs">{{ $t("shared.links.faq") }}</router-link>
					</p>
				</div>

				<div class="col-sm-6 col-lg-6 col-footer">
          <p>
						<router-link to="/contacto">{{ $t("shared.links.contact") }}</router-link>
					</p>
          <p class="mt-1 mt-lg-3">
						<router-link to="/aviso-de-privacidad">{{ $t("shared.links.nofPrivacy") }}</router-link>
					</p>
          <p class="mt-1 mt-lg-3">
						<router-link to="/aviso-legal">{{ $t("shared.links.legal") }}</router-link>
					</p>
					<p class="mt-1 mt-lg-3">
						<router-link to="/terminos-y-condiciones">{{ $t("shared.links.terms") }}</router-link>
					</p>
				</div>

				<div class="col-12 col-line"></div>

        <!-- <div class="col-12 col-extra">
          <h6 class="title">{{ $t("shared.texts.fideicomisos") }}</h6>

          <div class="row">
            <div class="col-lg-6 col-ar">
              <p>
                {{ $t("shared.texts.art27") }}
              </p>
            </div>

            <div class="col-lg-6 col-ar">
              <p>
                {{ $t("shared.texts.art395") }}
              </p>
            </div>

            <div class="col-lg-6 col-ar">
              <p>
                {{ $t("shared.texts.art101114") }}
              </p>
            </div>

            <div class="col-lg-6 col-ar">
              <p>
                {{ $t("shared.texts.art2") }}
              </p>
            </div>
          </div>
        </div> -->

				<div class="col-12 col-copyright">
					<h5>© LIONWARD {{ currentYear }}</h5>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: 0,
    }
  },

  mounted() {
    this.currentYear = new Date().getFullYear();
  }
}
</script>
