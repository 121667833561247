<template lang="html">
  <div id="services-page">

    <section class="main-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-12 col-titles">
            <h1 class="title-s1">{{ $t("pages.services.titles.services") }}</h1>
            <h2 class="title-s2">{{ $t("pages.services.titles.ourServices") }}</h2>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-6 col-lg-4 box-service-sample-1">
            <router-link class="box-service" to="/servicio/1">
              <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/services/service-1.jpg)' }"></div>

              <div class="box-text">
                <h6 class="name">{{ $t("pages.services.allServices.s1Title") }}</h6>

                <div class="short-descr" v-html='$t("pages.services.allServices.s1")'></div>
              </div>
            </router-link>
          </div>

          <div class="col-md-6 col-lg-4 box-service-sample-1">
            <router-link class="box-service" to="/servicio/2">
              <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/services/service-2.jpg)' }"></div>

              <div class="box-text">
                <h6 class="name">{{ $t("pages.services.allServices.s2Title") }}</h6>

                <div class="short-descr" v-html='$t("pages.services.allServices.s2")'></div>
              </div>
            </router-link>
          </div>

          <div class="col-md-6 col-lg-4 box-service-sample-1">
            <router-link class="box-service" to="/servicio/3">
              <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/services/service-3.jpg)' }"></div>

              <div class="box-text">
                <h6 class="name">{{ $t("pages.services.allServices.s3Title") }}</h6>

                <div class="short-descr" v-html='$t("pages.services.allServices.s3")'></div>
              </div>
            </router-link>
          </div>
        </div>

        <div class="d-block text-center mt-4">
          <router-link class="btn btn-s1 btn-fide" to="/fideicomisos">{{ $t("pages.trust.titles.title1") }}</router-link>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      services: []
    }
  }
}
</script>
