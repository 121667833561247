const messages = {
  es: {
    // == Shared ==
    shared: {
      links: {
        home: 'Inicio',
        aboutUs: 'Nosotros',
        services: 'Servicios',
        faq: 'F.A.Q.',
        contact: 'Contacto',
        nofPrivacy: 'Aviso de privacidad',
        legal: 'Aviso legal',
        terms: 'Términos y condiciones',
      },

      texts: {
        regulation: 'Quienes Nos Regulan',
        fideicomisos: 'Acerca de la regulación de los Fideicomisos en México',
        art27: 'Artículo 27 Constitución Política de los Estados Unidos Mexicanos. ',
        art395: 'Artículo 395 Ley General de Títulos y Operaciones de Crédito.',
        art101114: 'Artículo 10, 11 y 14 Ley de Inversión Extranjera.',
        art2: 'Artículo 2 Ley de Instituciones de Crédito.',
      },
    },
    // == ==

    // Pages ==
    pages: {
      home: {

      },

      aboutUs: {
        titles: {
          us: 'NOSOTROS',
          aboutLionward: 'Sobre',
          mission: 'Misión',
          vision: 'Visión',
        },

        texts: {
          content: 'Somos una SOFOM, una entidad financiera autorizada, y prestamos un servicio integral para el diseño, constitución y administración de Fideicomisos de Garantía y Fideicomisos de Garantía en Zona Restringida para Fines Residenciales y Fideicomisos de Garantía como Medio de Pago, exclusivamente. Cuidando en todo momento su seguridad, tiempo e intereses.',
          content2: 'Somos una SOFOM, una entidad financiera autorizada, y prestamos un servicio integral para el diseño, constitución y administración de Fideicomisos de Garantía y Fideicomisos de Garantía en Zona Restringida para Fines Residenciales y Fideicomisos de Garantía como Medio de Pago, exclusivamente. Cuidando en todo momento su seguridad, tiempo e intereses.',
          mission: 'Establecer una empresa de servicios fiduciarios de garantía ágil, segura, confiable, con procesos sencillos y que saque provecho de las nuevas tecnologías para brindar sus servicios.',
          vision: 'Ser en un referente de estabilidad, agilidad y servicio en el mercado.',
        }
      },

      services: {
        titles: {
          services: 'SERVICIOS',
          ourServices: 'Nuestros Servicios',
        },

        allServices: {
          s1Title: 'FIDEICOMISO DE GARANTÍA',
          s1: `<p>Son fideicomisos de garantía aquellos cuyo fin sea asegurar al acreedor (fideicomisario) el cumplimiento de una obligación del fideicomitente (deudor) y su preferencia en el pago. El cumplimiento de la obligación es respaldado no por los activos del deudor, sino por los activos (bienes muebles, inmuebles, derechos y/o recursos) que aporta al patrimonio del fideicomiso para dicho fin, en caso de incumplimiento, se iniciaría el proceso de ejecución de los bienes que integran el patrimonio para satisfacer el pago de dicha obligación.</p>
          <p>El fideicomiso de garantía puede garantizar créditos presentes o futuros.</p>`,
          s2Title: 'FIDEICOMISO DE GARANTÍA EN ZONA RESTRINGIDA CON FINES RESIDENCIALES',
          s2: `<p>Fideicomiso a través del cual se permite a los extranjeros adquirir el uso y disfrute de un inmueble ubicado en la zona restringida (franja de 100 km a lo largo de las fronteras y de 50 km en las playas) con fines residenciales. </p><p>Mientras que el fiduciario conserva legalmente la propiedad del inmueble, el extranjero es el beneficiario del uso y goce del inmueble.</p>`,
          s3Title: 'FIDEICOMISO DE GARANTÍA COMO MEDIO DE PAGO',
          s3: '<p>Es una modalidad del fideicomiso de garantía, con la diferencia de que los recursos provenientes de la explotación y/o desarrollo del patrimonio fideicomitido constituye la fuente de pago de la obligación que ostenta el fideicomitente a favor del fideicomisario.</p>',
        },

        texts: {
          contactUs: 'CONTACTAR'
        }
      },

      trust: {
        titles: {
          title1: '¿Quieres saber más de Fideicomisos?',
        },

        all: {
          opt1Title: 'Conoce algunos conceptos básicos del fideicomiso',
          opt1Content: `
            <h5>¿Qué es un fideicomiso?</h5>
            <p>
              Es un contrato mediante el cual una persona física o moral, nacional o extranjera, afecta ciertos bienes o derechos para un fin lícito y determinado, en beneficio propio o de un tercero, encomendando la realización de dicho fin a una institución fiduciaria, expresamente autorizada para fungir como tal.
            </p>

            <br>
            <h5>¿Qué es una fiduciaria?</h5>
            <p>
              Institución que recibe en propiedad fiduciaria dichos bienes, con la misión de cumplir las disposiciones o fines establecidos por el fideicomitente en el Contrato de Fideicomiso.
            </p>
            <p class="mt-2">
              A diferencia de otros sistemas fiduciarios en el mundo, la legislación mexicana reserva esta capacidad exclusivamente a las instituciones bancarias, y a otras instituciones financieras que están autorizadas para actuar como fiduciario conforme a la legislación mexicana.
            </p>

            <br>
            <h5>¿Qué es un fideicomitente?</h5>
            <p>
              Persona que aporta al fideicomiso bienes o derechos de su propiedad, estableciendo condiciones de administración y distribución.
            </p>

            <br>
            <h5>¿Qué es un fideicomisario?</h5>
            <p>
              Persona que recibe el beneficio del fideicomiso, puede ser el propio fideicomitente o un tercero.
            </p>
          `,
          opt2Title: 'Beneficios de un Fideicomiso',
          opt2Content: `
            <ul>
              <li>
                <h5>EFICIENCIA</h5>
                <ul>
                  <li>Eliminación de la necesidad de la intervención judicial para la ejecución de la garantía.</li>
                  <li>Facilidad de dirimir a través de Arbitraje toda controversia suscitada con motivo del fideicomiso.</li>
                  <li>Menor tiempo, costos y documentación para la constitución del Fideicomiso derivado de facilidades otorgadas por la legislación mexicana a las SOFOMES.</li>
                </ul>
              </li>
              <li class="mt-3">
                <h5>FISCALES</h5>
                <ul>
                  <li>El Fideicomiso es fiscalmente transparente, es decir, los derechos y obligaciones fiscales derivados de las operaciones que se realicen a través de un fideicomiso recaen en el Fideicomitente (que aporta) o los Fideicomisarios (que reciben).</li>
                </ul>
              </li>
              <li class="mt-3">
                <h5>EMBARGABILIDAD</h5>
                <ul>
                  <li>El Fideicomitente trasmite la propiedad del patrimonio al Fiduciario, por lo que éste último se vuelve el Propietario. Los activos del fideicomiso dejan de pertenecer a la persona que los origina y quedan afectados exclusivamente al fin preestablecido en el contrato.</li>
                </ul>
              </li>
              <li class="mt-3">
                <h5>SECRECÍA</h5>
                <ul>
                  <li>La constitución del fideicomiso da seguridad y confidencialidad respecto del patrimonio fideicomitido, y a las partes que lo conforman.</li>
                </ul>
              </li>
            </ul>
          `,
          opt3Title: 'Acerca de la regulación de los Fideicomisos en México',
          opt3Content: `
            <ul>
              <li>Artículo 27 Constitución Política de los Estados Unidos Mexicanos.</li>
              <li>Artículo 395 Ley General de Títulos y Operaciones de Crédito.</li>
              <li>Artículo 10, 11 y 14 Ley de Inversión Extranjera.</li>
              <li>Artículo 2 Ley de Instituciones de Crédito.</li>
            </ul>
          `,
        }
      },

      faqs: {
        titles: {
          faq: 'F.A.Q.'
        },

        texts: {
          qa1Title: '¿Qué es un fideicomiso?',
          qa1: `
            <p>El Fideicomiso es la adaptación en México de un Trust, por lo que en esencia es un Trust mexicano. Es un contrato mediante el cual una persona física o moral, nacional o extranjera, afecta ciertos bienes o derechos para un fin lícito y determinado, en beneficio propio o de un tercero, encomendando la realización de dicho fin a una institución fiduciaria, expresamente autorizada para fungir como tal.</p>
          `,
          qa2Title: '¿Qué es una fiduciaria?',
          qa2: `
            <p>Institución que recibe en propiedad fiduciaria dichos bienes, con la misión de cumplir las disposiciones o fines establecidos por el fideicomitente en el Contrato de Fideicomiso.<br>
            A diferencia de otros sistemas fiduciarios en el mundo, la legislación mexicana reserva esta capacidad exclusivamente a las instituciones bancarias, y a otras instituciones financieras que estén facultadas para actuar como fiduciarias conforme a la legislación mexicana.</p>
          `,
          qa3Title: '¿Qué es un fideicomitente?',
          qa3: `
            <p>Persona que aporta al fideicomiso bienes o derechos de su propiedad, estableciendo condiciones de administración y distribución.</p>
          `,
          qa4Title: '¿Qué es un fideicomisario?',
          qa4: `
            <p>Persona que recibe el beneficio del fideicomiso, puede ser el propio fideicomitente o un tercero.</p>
          `,
          qa5Title: 'Naturaleza del Patrimonio del Fideicomiso',
          qa5: `
            <p>
            En el momento en que se constituye un fideicomiso, el fideicomisario adquiere el derecho legal sobre los bienes fideicomitidos, que dejan de ser propiedad del fideicomitente. Esta transmisión se da aún en los casos en que el fideicomitente y el beneficiario sean la misma persona, ya que se considera que el fiduciario es el derechohabiente de dichos bienes durante la vigencia del fideicomiso. La legislación mexicana otorga a los bienes fideicomitidos una naturaleza autónoma; dichos bienes fideicomitidos son distintos a los bienes de las partes del fideicomiso y aunque estén bajo el control del fiduciario, dichos bienes no se consideran parte de su patrimonio dado que se destinan a un fin determinado conforme al fideicomiso.<br>
            Ni el fiduciario ni el fideicomitente pueden realizar individualmente actos de dominio respecto de los bienes fideicomitidos, pudiendo actuar únicamente dentro de los límites que se hayan establecido en el correspondiente contrato de fideicomiso. A diferencia del concepto de propiedad civil en el que una parte tiene derecho a gozar y disponer libremente de sus bienes, en la titularidad fiduciaria tal derecho no existe ya que las partes no pueden disponer de la posesión y de los bienes en su beneficio.
            </p>
            <p class="mt-3">
            Los activos aportados a un fideicomiso conforman un patrimonio independiente el cual no se constituye como propiedad del fiduciario, por lo que si el fiduciario cae en bancarrota, dichos activos no se ven afectados, son asumidos por el gobierno y asignados a otro fiduciario.
            </p>
          `,
          qa6Title: '¿Qué es la Zona Restringida de acuerdo con la legislación mexicana?',
          qa6: `
            <p>La zona restringida es la tierra que se encuentra a 100 kilómetros de cualquier frontera mexicana y 50 kilómetros de cualquier línea costera,  en dicha zona, los extranjeros no pueden adquirir bienes inmuebles a título oneroso, se requiere un fideicomiso para mantener la propiedad a favor del extranjero.</p>
          `,
          qa7Title: '¿Cómo es el proceso para adquirir una propiedad a través de un Fideicomiso de Garantía con fines residenciales en la Zona Restringida en México?',
          qa7: `
            <p>
              El proceso de constitución suele llevarse a cabo designando al vendedor/ desarrolaldor como fideicomitente y transfiriendo irrevocablemente la titularidad de la propiedad al fideicomisario. A continuación, el fideicomisario mantiene la propiedad en beneficio del beneficiario. Normalmente, el beneficiario tendrá el derecho ilimitado de uso y goce de la propiedad, así como el beneficio de cualquier renta o producto de la venta, siempre y cuando los activos del fideicomiso se vendan, si así lo permite el fideicomiso.<br>
              <br>
              Las partes deben formalizar la constitución del fideicomiso mediante la transmisión de los bienes inmuebles ante un Notario Público mexicano. <br>
              <br>
              Al igual que cualquier otra transacción de bienes raíces, la creación de un fideicomiso de esta índole también requerirá la debida diligencia estándar sobre la propiedad, avalúos, inspecciones, búsquedas de títulos, la obtención de certificados de no gravamen, certificados de no deuda (sobre impuestos a la propiedad) y cualquier otro requisito habitual o estatutario bajo las leyes locales y estatales para el cierre de bienes raíces.<br>
              <br>
              El fideicomiso es un fideicomiso de garantía, por lo tanto, el Beneficiario puede designar en cualquier momento un préstamo o crédito para ser garantizado por los activos del fideicomiso y obtener liquidez de la propiedad.
            </p>
          `,
          qa8Title: '¿Puedo nombrar a un beneficiario substituto del Fideicomiso?',
          qa8: `
            <p>Sí, otra característica importante de un Fideicomiso de Garantía para fines residenciales en la Zona Restringida de México es que permiten al Beneficiario designar a un "Beneficiario Sustituto" al momento de la creación del fideicomiso. A la muerte del Beneficiario, el Beneficiario Sustituto lo sustituye como Beneficiario del Fideicomiso.</p>
          `,
          qa9Title: '¿Qué es una SOFOM?',
          qa9: `
            <p>Las Sociedades Financieras de Objeto Múltiple (Sofomes) son sociedades anónimas que cuentan con un registro vigente ante la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros (Condusef), y pueden ser reguladas y no reguladas.<br>
            Tanto las Sofomes reguladas como las Sofomes no reguladas están sujetas a la inspección y vigilancia de la Comisión Nacional Bancaria y de Valores (CNBV), para verificar el cumplimiento de las disposiciones de lavado de dinero y financiamiento al terrorismo de conformidad con el Artículo 95 Bis de la Ley General de Organizaciones y Actividades Auxiliares de Crédito.
            </p>
          `,
        }
      },

      contact: {
        titles: {
          contact: 'ATENCIÓN A CLIENTES',
          contactUs: 'Contáctanos',
        },

        form: {
          name: 'Nombre completo',
          nameLabel: 'Escribe tu nombre',
          email: 'Correo electrónico',
          emailLabel: 'Escribe tu correo electrónico',
          message: 'Mensaje',
          messageLabel: 'Escribe tu mensaje...',
          btnSendMsg: 'Enviar mensaje',
        }
      },

      nofPrivacy: {
        title: 'AVISO DE PRIVACIDAD INTEGRAL DE LIONWARD'
      },

      legal: {
        title: 'AVISO LEGAL DE LA PÁGINA WEB'
      },

      terms: {
        title: 'TÉRMINOS Y CONDICIONES'
      },
    }
    // == ==
  },

  en: {
    // == Shared ==
    shared: {
      links: {
        home: 'Home',
        aboutUs: 'About us',
        services: 'Services',
        faq: 'F.A.Q.',
        contact: 'Contact',
        nofPrivacy: 'Privacy Notice',
        legal: 'Website disclaimer',
        terms: 'Terms and Conditions',
      },

      texts: {
        regulation: 'Who Regulate Us',
        fideicomisos: 'About the regulation of Trusts in Mexico',
        art27: 'Article 27 Political Constitution of the United Mexican States.',
        art395: 'Article 395 General Law of Credit Titles and Operations.',
        art101114: 'Article 10, 11 and 14 Foreign Investment Law.',
        art2: 'Article 2 Credit Institutions Law.',
      }
    },
    // == ==

    // Pages ==
    pages: {
      home: {

      },

      aboutUs: {
        titles: {
          us: 'ABOUT US',
          aboutLionward: 'About',
          mission: 'Mission',
          vision: 'Vision'
        },

        texts: {
          content: 'As a SOFOM, an authorized financial institution, we offer end-to-end services for the design, incorporation, and administration of Guarantee Trusts and Guarantee Trusts for Residential Purposes in Mexico’s Restricted Zone, and Guarantee Trust as Payment Vehicle, exclusively. Always taking care of your security, time, and interests.',
          content2: 'As a SOFOM, an authorized financial institution, we offer end-to-end services for the design, incorporation, and administration of Guarantee Trusts and Guarantee Trusts for Residential Purposes in Mexico’s Restricted Zone, and Guarantee Trust as Payment Vehicle, exclusively. Always taking care of your security, time, and interests.',
          mission: 'We are committed to serve as an agile, safe and reliable trust services company, with simple processes and taking advantage of new technologies to provide our services.',
          vision: 'We aim to be a reference of stability, agility and service in the market.',
        }
      },

      services: {
        titles: {
          services: 'SERVICES',
          ourServices: 'Our services',
        },

        allServices: {
          s1Title: 'GUARANTEE TRUST',
          s1: `<p>It is the fiduciary business through which the obligations of a debtor (Settlor) in favor of one or more creditors (beneficiaries) are protected, through the allocation of assets to said purpose, to guarantee the fulfillment of obligations or obtain the satisfaction of the credit in case of default. The credit in this case is backed not by the debtor’s assets, but by the assets (movable and immovable property, rights and/or resources) that the debtor incorporates to the trust patrimony for such purpose.</p>
          <p>The Guarantee Trust may guarantee present or future credits.</p>`,
          s2Title: 'GUARANTEE TRUST FOR RESIDENTIAL PURPOSES IN MEXICO’S RESTRICTED ZONE',
          s2: `<p>Trust through which foreigners are allowed to acquire the use and enjoyment of a residential property located in Mexico’s Restricted Zone (50 kilometers from Mexican coasts and 100 kilometers from Mexican borders).</p><p>While the trustee legally retains ownership of the property, the foreigner is the beneficiary of the use and enjoyment of the property.</p>`,
          s3Title: 'GUARANTEE TRUST AS PAYMENT VEHICLE',
          s3: '<p>It is a modality of the guarantee trust, though it differs by the fact that the resources coming from the exploitation and/or development of the trust patrimony constitute the source of payment of the obligation held by the Settlor in favor of the trustee.</p>',
        },

        texts: {
          contactUs: 'CONTACT'
        }
      },

      trust: {
        titles: {
          title1: 'Want to know more about Trusts?',
        },

        all: {
          opt1Title: 'Some basic concepts about trusts',
          opt1Content: `
            <h5>What is a trust?</h5>
            <p>
              By virtue of a trust, a person, named “fideicomitente” or “settlor,” transfers to a trustee, the entitlement to one or more assets or rights to be destined to legal and determined purpose, the fulfillment of which is entrusted to the trustee. The formation of a trust must always be evidenced in writing. Once an asset is contributed to a trust, such asset ceases to be the property of the settlor and becomes the property of the trust, forming part of the trust’s assets.
            </p>

            <br>
            <h5>What is a Trustee?</h5>
            <p>
              The party for receiving the trust assets and has the obligation to maintain them and use them for the sole purpose or purposes for which the trust was incorporated.
            </p>
            <p class="mt-2">
              As opposed to other trust systems in the world, Mexican law reserves this capacity exclusively to banking institutions, and to other financial institutions which are entitled to act as trustee under Mexican law.
            </p>

            <br>
            <h5>What is a Settlor?</h5>
            <p>
              The party, which can either be an individual or a legal entity, who incorporates the trust and contributes the assets which will become a part of the trust’s assets.  The settlor designates the terms of management and purposes of the Trust.
            </p>

            <br>
            <h5>What is a Beneficiary?</h5>
            <p>
              The party, which can either be an individual or legal entity, who receives the benefits of the Trust, it may be the Settlor or a third party.
            </p>
          `,
          opt2Title: 'Benefits of a Trust',
          opt2Content: `
            <ul>
              <li>
                <h5>EFFICIENCY</h5>
                <ul>
                  <li>No judicial intervention is required for the execution of the guarantee.</li>
                  <li>All disputes arising in connection with the trust may be settled through arbitration.</li>
                  <li>Less time, costs and documentation for the incorporation of the Trust due to the facilities granted by the Mexican legislation to SOFOMES.</li>
                </ul>
              </li>
              <li class="mt-3">
                <h5>TAXATION</h5>
                <ul>
                  <li>The Trust is tax "transparent", meaning that the Tax rights and obligations arising from the operations carried out through a trust are borne by the Settlor (who contributes) or the Beneficiary (who receives).</li>
                </ul>
              </li>
              <li class="mt-3">
                <h5>EMBARGABILITY</h5>
                <ul>
                  <li>The Settlor transfers the ownership of the assets to the Trustee, whereby the latter becomes the Owner. The assets of the trust cease to belong to the person who originated them and are used exclusively for the purpose pre-established in the Agreement.</li>
                </ul>
              </li>
              <li class="mt-3">
                <h5>SECRECY</h5>
                <ul>
                  <li>The incorporation of a trust provides security and confidentiality with respect to the trust assets and the parties involved.</li>
                </ul>
              </li>
            </ul>
          `,
          opt3Title: 'About Trusts regulation in Mexico',
          opt3Content: `
            <ul>
              <li>Article 27 of Political Constitution of the United States of Mexico.</li>
              <li>Article 395 of Negotiable Instruments and Credit Operations Law.</li>
              <li>Article 10, 11 y 14 of Foreign Investment Law.</li>
              <li>Article 2 of Financial Institutions Law.</li>
            </ul>
          `,
        }
      },

      faqs: {
        titles: {
          faq: 'F.A.Q.'
        },

        texts: {
          qa1Title: 'What is a trust or “fideicomiso”?',
          qa1: `
            <p>The Fideicomiso is the adaptation to México of a trust, so it is in essence a Mexican trust. By virtue of a trust, a person, named “fideicomitente” or “settlor,” transfers to a trustee, the entitlement to one or more assets or rights to be destined to legal and determined purpose, the fulfillment of which is entrusted to the trustee. The formation of a trust must always be evidenced in writing. Once an asset is contributed to a trust, such asset ceases to be the property of the settlor and becomes the property of the trust, forming part of the trust’s assets.</p>
          `,
          qa2Title: 'What is a Trustee?',
          qa2: `
            <p>The party receiving the trust assets and has the obligation to maintain them and use them for the sole purpose or purposes for which the trust was incorporated.<br>
            <br>
            As opposed to other trust systems in the world, Mexican law reserves this capacity exclusively to banking institutions, and to other financial institutions which are entitled to act as trustee under Mexican law.</p>
          `,
          qa3Title: 'What is a Settlor? ',
          qa3: `
            <p>The party, which can either be an individual or a legal entity, who incorporates the trust and contributes the assets which will become a part of the trust’s assets.  The settlor designates the terms of management and purposes of the Trust.</p>
          `,
          qa4Title: 'What is a Beneficiary?',
          qa4: `
            <p>The party, which can either be an individual or legal entity, who receives the benefits of the Trust, it may be the Settlor or a third party.</p>
          `,
          qa5Title: 'Nature of the Trust Estate',
          qa5: `
            <p>
            At the time a trust is incorporated, the trustee becomes legally entitled to the trust assets, which ceases to be property of the settlor. This transfer takes place even in such cases in which the settlor and beneficiary is the same person, given the fact that the trustee is considered the entitled party to such assets during the term of the trust. Mexican law grants the trust assets an autonomous nature; such trust assets are different than the assets of the trust parties and even if under the control of the trustee, such assets are not deemed to be part of its assets given the fact that they are destined to a determined purpose pursuant to the trust.<br>
            <br>
            Neither the trustee nor the settlor may individually perform acts of ownership with respect to the trust assets, being able to act only within the limits which have been set forth in the corresponding trust agreement. Contrary to the civil property concept in which a party has the right to freely enjoy and dispose of its assets, under the trust entitlement such right does not exist since the parties may not dispose of the possession and the assets to their benefit.<br>
            <br>
            Assets contributed to a trust conform an independent patrimony that does not consolidate as property of the trustee, so if the trustee falls in bankruptcy, such assets are not affected, are taken by the government and allocated to another trustee.
            </p>
          `,
          qa6Title: 'What is the Restricted Zone according to Mexican Regulation?',
          qa6: `
            <p>The restricted zone is defined as the territory comprising 50 kilometers from the Mexican coastline and 100 kilometers from the Mexican borders, and in such zone, foreigners cannot acquire Real Estate property fee simple, a trust to holds ownership in behalf of the foreigner is required.</p>
          `,
          qa7Title: 'How is the process to purchase a property through a Guarantee Trust for residential purposes in Mexico’s Restricted Zone?',
          qa7: `
            <p>
            The formation process usually takes place by having the seller/developer act as the settlor and by irrevocably transferring title to the property, to the trustee. The trustee then holds the property for the benefit of the beneficiary. The beneficiary will normally have the unrestricted right to enjoy and use the property, as well the benefit of any rental and sale proceeds, if and when the trusts assets are sold, provided the trust allows it.<br>
            <br>
            The parties must formalize the creation of the trust by transferring the real property in front of a Mexican Notary Public. <br>
            <br<
            Like any other real estate transaction, the creation of a land trust will also require the standard due diligence on the property, appraisals, inspections, title searches, the obtaining of certificates of no-liens, certificates of no-debt (on property taxes) and any other customary or statutory requirement under local and state laws for closing on real estate. <br>
            <br>
            The trust is a warranty trust, therefore, the Beneficiary may appoint at any time a loan or credit to be warrantied by the trust assets and obtain equity out of the property.
            </p>
          `,
          qa8Title: 'Can I appoint a Substitute Beneficiary of the Trust?',
          qa8: `
            <p>Yes, another important feature of a Guarantee Trust for residential purposes in Mexico’s Restricted Zone is that they allow the beneficiary to appoint a “substitute beneficiary” at the time of the trust’s creation. Upon the beneficiary’s death, the substitute beneficiary takes over, as the trust’s beneficiary.</p>
          `,
          qa9Title: 'What is a SOFOM?',
          qa9: `
            <p>
            Multipurpose Financial Entities (in Spanish “Sociedades Financieras de Objeto Múltiple” and by its abbreviation “Sofomes”) are corporations that have a current registration with the National Commission for the Protection and Defense of Users of Financial Services (in Spanish ”Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros” and by its abbreviation “Condusef”), which can be regulated or non-regulated.<br>
            <br>
            Both regulated and non-regulated Sofomes are subject to inspection and surveillance by the National Banking and Securities Commission (in Spanish Comisión Nacional Bancaria y de Valores, and by its abbreviation “CNBV”), to verify compliance with money laundering and terrorist financing provisions in accordance with Article 95 Bis of the General Law of Organizations and Activities Auxiliary to Credit.
            </p>
          `,
        }
      },

      contact: {
        titles: {
          contact: 'CONTACT',
          contactUs: 'Contact us',
        },

        form: {
          name: 'Full name',
          nameLabel: 'Type your name',
          email: 'Email',
          emailLabel: 'Type your email',
          message: 'Message',
          messageLabel: 'Type your message...',
          btnSendMsg: 'Send message',
        }
      },

      nofPrivacy: {
        title: 'LIONWARD’S PRIVACY NOTICE'
      },

      legal: {
        title: 'WEBSITE DISCLAIMER'
      },

      terms: {
        title: 'NOTICE OF TERMS AND CONDITIONS'
      },
    }
    // == ==
  },
}

export default messages;
