<template lang="html">
  <div id="home-page">
    <section class="banners-section">
      <swiper class="swiper swiper-desktop" :options="bannersOptions">
        <swiper-slide>
          <router-link class="box-img" to="/fideicomisos">
            <img src="public/images/pages/home/banner-1.jpg" v-if="$i18n.locale == 'es'">
            <img src="public/images/pages/home/banner-1-eng.jpg" v-else>
          </router-link>
        </swiper-slide>

        <!-- <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div> -->
      </swiper>

      <swiper class="swiper swiper-mobile" :options="bannersOptions">
        <swiper-slide>
          <router-link class="box-img" to="/fideicomisos">
            <img src="public/images/pages/home/banner-1-m.jpg" v-if="$i18n.locale == 'es'">
            <img src="public/images/pages/home/banner-1-m-eng.jpg" v-else>
          </router-link>
        </swiper-slide>

        <!-- <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div> -->
      </swiper>
    </section>

    <section class="about-section" id="s-about">
      <div class="box-content">
        <div class="container oversized-container">
          <div class="row">
            <div class="col-lg-6 col-titles">
              <!-- <h5 class="title-s1">{{ $t("pages.aboutUs.titles.us") }}</h5> -->
              <h5 class="title-s2">{{ $t("pages.aboutUs.titles.aboutLionward") }} <img class="logo" src="public/images/logo-txt-noslogan.svg" /></h5>
            </div>

            <div class="col-lg-6 col-info">
              <p>
                {{ $t("pages.aboutUs.texts.content") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="services-section">
      <h2 class="title-s1">{{ $t("pages.services.titles.services") }}</h2>
      <h2 class="title-s2">{{ $t("pages.services.titles.ourServices") }}</h2>

      <div class="container-fluid carousel-container">
        <div class="box-carousel">
          <swiper class="swiper" :options="servicesOptions">
            <swiper-slide>
              <router-link class="box-service" to="/servicio/1">
                <div class="col placed-backg col-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/services/service-1.jpg)' }"></div>

                <div class="col col-text">
                  <div class="content">
                    <h5 class="title">{{ $t("pages.services.allServices.s1Title") }}</h5>

                    <div class="descr" v-html='$t("pages.services.allServices.s1")'>
                    </div>
                  </div>
                </div>
              </router-link>
            </swiper-slide>

            <swiper-slide>
              <router-link class="box-service" to="/servicio/2">
                <div class="col placed-backg col-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/services/service-2.jpg)' }"></div>

                <div class="col col-text">
                  <div class="content">
                    <h5 class="title">{{ $t("pages.services.allServices.s2Title") }}</h5>

                    <div class="descr" v-html='$t("pages.services.allServices.s2")'>
                    </div>
                  </div>
                </div>
              </router-link>
            </swiper-slide>

            <swiper-slide>
              <router-link class="box-service" to="/servicio/3">
                <div class="col placed-backg col-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/services/service-3.jpg)' }"></div>

                <div class="col col-text">
                  <div class="content">
                    <h5 class="title">{{ $t("pages.services.allServices.s3Title") }}</h5>

                    <div class="descr" v-html='$t("pages.services.allServices.s3")'>
                    </div>
                  </div>
                </div>
              </router-link>
            </swiper-slide>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>

        <div class="d-block text-center mt-4">
          <router-link class="btn btn-s1 btn-fide" to="/fideicomisos">{{ $t("pages.trust.titles.title1") }}</router-link>
        </div>
      </div>
    </section>

    <section class="contact-section" id="s-contact">
      <div class="container oversized-container">
      <div class="row">
        <div class="col-lg-6 offset-lg-6 col-form">
          <div class="box-titles">
            <h2 class="title-s1">{{ $t("pages.contact.titles.contact") }}</h2>
            <h2 class="title-s2">{{ $t("pages.contact.titles.contactUs") }}</h2>
            <p class="txt-email">
              <span>support@lion-ward.com</span>
            </p>
          </div>

          <div class="box-form">
            <b-form @submit="onSubmit">
              <b-form-group class="custom-f-group-s1"
                :label="$t('pages.contact.form.name')">
                <b-form-input
                  v-model="form.name"
                  type="text"
                  :placeholder="$t('pages.contact.form.nameLabel')"
                  size="lg"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group class="custom-f-group-s1"
                :label="$t('pages.contact.form.email')">
                <b-form-input
                  v-model="form.email"
                  type="email"
                  :placeholder="$t('pages.contact.form.emailLabel')"
                  size="lg"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group class="custom-f-group-s1"
                :label="$t('pages.contact.form.message')">
                <b-form-textarea
                  v-model="form.msg"
                  :placeholder="$t('pages.contact.form.messageLabel')"
                  rows="6"
                  max-rows="6"
                  size="lg"
                ></b-form-textarea>
              </b-form-group>

              <p class="mt-4 pt-2 text-right">
                <b-button type="submit" class="btn-s1">{{ $t('pages.contact.form.btnSendMsg') }}</b-button>
              </p>
            </b-form>
          </div>
        </div>
      </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: null,
        email: null,
        msg: null,
      },

      services: [],

      // == Carousels options ==
      bannersOptions: {
        loop: true,
        effect: 'fade',

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },

      servicesOptions: {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 0,
        loop: true,
        speed: 600,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: true,
        },

        breakpoints: {
          992: {
            slidesPerView: 'auto',
            centeredSlides: true,
          },
          2: {
            slidesPerView: 1,
            centeredSlides: false,
          },
        }
      }
      // == ==
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-bottom-right',
        appendToast: true
      })
    },

    onSubmit(event) {
      event.preventDefault();

      var params = this.form;
      var apiURL = tools.url("/api/contact");

      axios.post( apiURL,params )
      .then( (response) => {
        // alert(response.data.response);
        this.makeToast('success', response.data.response, 'Mensaje enviado');
        // alert(response.data.response);

        for (let prop in this.form) {
          this.form[prop] = '';
        }
      })
      .catch( (error) => {
        // console.log(error.response.data);
        let errors = error.response.data;

        for (let prop in errors) {
          // console.log(errors[prop]);
          this.makeToast('danger', errors[prop], '¡Error!');
        }
      });
    },
  }
}
</script>
