<template lang="html">
  <div id="text-page">
    <div class="container">
      <h1 class="mb-2 page-title">{{ $t("pages.legal.title") }}</h1>

      <!-- Esp -->
      <div v-if="$i18n.locale == 'es'">
        <h5>RESPONSABILIDAD DEL USUARIO</h5>
        <p>
          El uso del Sitio Web es responsabilidad exclusiva del usuario. El Sitio Web y sus propietarios no serán responsables de ningún daño o perjuicio que pueda surgir del uso de la información contenida en el Sitio Web o de las operaciones realizadas a través de él.
        </p>

        <br />
        <h5>DIVULGACIÓN DE INFORMACIÓN PERSONAL</h5>
        <p>
          No divulgaremos sin su consentimiento, ninguna información de identificación personal acerca de usted como usuario individual a ningún tercero. Cuando sea necesario, podemos utilizar su información personal para asegurar el cumplimiento de políticas de la empresa y leyes aplicables.<br />
          <br />
          Finalmente, el Sitio Web se reserva el derecho de divulgar información de los miembros de la lista en casos especiales en los que tengamos motivo para creer que divulgar esta información es necesario para identificar, comunicarse o accionar legalmente contra alguien que pudiera interferir o causar perjuicios (con intención o sin ella) a los derechos o la propiedad del Sitio Web, de otros visitantes, o de cualquier otra persona que pudiera resultar perjudicada por tales actividades. El Sitio Web también se reserva el derecho de divulgar información de miembros de la lista o visitantes cuando creamos de buena fe que la ley lo requiere.
        </p>

        <br />
        <h5>USO DE COOKIES</h5>
        <p>
          En nuestro sitio web utilizamos cookies de sesión para facilitarle el uso de nuestras páginas web. Se trata de pequeños archivos de texto que se almacenan en su disco duro durante su visita a nuestro sitio web y se eliminan cuando cierra su navegador, dependiendo de su configuración. El almacenamiento de cookies puede deshabilitarse o desactivarse en la configuración de su navegador. Las cookies de sesión se borran automáticamente al cerrar el navegador. Las cookies persistentes permanecen en el equipo después de cerrarse el navegador.
        </p>

        <br />
        <h5>OBJETO DE CAMBIOS</h5>
        <p>
          Este Aviso puede actualizarse ocasionalmente, y cualquier cambio se publicará en el Sitio Web. Los usuarios son responsables de revisar periódicamente este Aviso para estar al tanto de cualquier modificación. El Sitio Web se reserva el derecho a modificar este descargo de responsabilidad en cualquier momento sin aviso previo.
        </p>

        <br />
        <h5>JURISDICCIÓN</h5>
        <p>
          Estas condiciones de uso se rigen por las leyes de los Estados Unidos Mexicanos, y usted acepta que los todas las controversias que deriven de o guarden relación con éstas, serán resueltas definitivamente de acuerdo con el Reglamento de Arbitraje del Centro de Arbitraje Empresarial, por uno o tres árbitros nombrados conforme a dicho Reglamento.<br />
          <br />
          En la medida en que cualquier autoridad competente considerara cualquier parte de estas condiciones de uso como no válidas, ilícitas o inaplicables, dicha parte se escindirá, en dicha medida, de los demás términos, los cuales permanecerán en pleno vigor y efecto según lo permita la ley.
        </p>

        <br />
        <h5>RECONOCIMIENTO</h5>
        <p>
          USTED RECONOCE QUE HA LEÍDO, COMPRENDE Y ACEPTA LAS PRÁCTICAS QUE SE DESCRIBEN EN ESTE DESCARGO DE RESPONSABILIDAD SOBRE EL CONTENIDO Y QUE ESTÁ DE ACUERDO EN QUEDAR OBLIGADO POR LOS TÉRMINOS DE USO QUE AQUÍ SE MENCIONAN.
        </p>
      </div>
      <!--  -->

      <!-- Eng -->
      <div v-if="$i18n.locale == 'en'">
        <h5>USER LIABILITY</h5>
        <p>
          The use of the Web Site is the sole responsibility of the user. The Web Site and its owners shall not be liable for any damages or injury that may arise from the use of the information contained on the Web Site or activities conducted through it.
        </p>

        <br />
        <h5>DISCLOSURE OF PERSONAL INFORMATION</h5>
        <p>
          We will not disclose without your consent, any personally identifying information about you as an individual user to any third party. When necessary, we may use your personal information to ensure compliance with company policies and applicable laws.<br />
          <br />
          Finally, the Website reserves the right to disclose information about list members in special cases where we have reason to believe that disclosing this information is necessary to identify, contact or bring legal action against someone who may be interfering with or causing injury (either intentionally or unintentionally) to the rights or property of the Website, other visitors, or anyone else that could be harmed by such activities. The Website also reserves the right to disclose list members' or visitors' information when we believe in good faith that the law requires it.
        </p>

        <br />
        <h5>USE OF COOKIES</h5>
        <p>
          We use session cookies on our website to facilitate your use of our web pages. These are small text files that are stored on your hard drive during your visit to our website and are deleted when you close your browser, depending on your browser settings. The storage of cookies can be disabled or deactivated in your browser settings. Session cookies are automatically deleted when you close your browser. Persistent cookies remain on your computer after you close your browser.
        </p>

        <br />
        <h5>SUBJECT TO CHANGE</h5>
        <p>
          This Notice may be updated from time to time, and any changes will be posted on the Web Site. Users are responsible for periodically reviewing this Notice to be aware of any modifications. The Web Site reserves the right to modify this disclaimer at any time without notice.
        </p>

        <br />
        <h5>JURISDICTION</h5>
        <p>
          These Terms of Use are governed by the laws of the United Mexican States, and you agree that all disputes arising out of or relating to these Terms of Use shall be finally settled under the Arbitration Rules of the Centro de Arbitraje Empresarial by one or three arbitrators appointed in accordance with such Rules.<br />
          <br />
          To the extent that any part of these Terms of Use shall be held by any competent authority to be invalid, unlawful or unenforceable, such part shall, to that extent, be severed from the remaining terms, which shall remain in full force and effect as permitted by law.
        </p>

        <br />
        <h5>ACKNOWLEDGEMENT</h5>
        <p>
          HEREBY ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND AND ACCEPT THE PRACTICES DESCRIBED IN THIS CONTENT DISCLAIMER AND AGREE TO BE BOUND BY THE TERMS OF USE STATED HEREIN.
        </p>
      </div>
      <!--  -->
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
    }
  },
}
</script>
