<template lang="html">
  <div id="service-detail-page">

    <section class="placed-backg banner-section" v-bind:style="{ backgroundImage: 'url(public/images/pages/services/bg-s-3.jpg)' }">
    </section>

    <section class="main-section">
      <div class="container">
        <div class="box-title">
          <h1 class="title-s1">{{ $t("pages.services.titles.services") }}</h1>
          <h2 class="s-title">{{ $t("pages.services.allServices.s3Title") }}</h2>
        </div>

        <div class="box-content">
          <div v-html='$t("pages.services.allServices.s3")'></div>
          <p class="mt-4">
            <router-link class="t-150 btn-contact" to="/contacto">{{ $t("pages.services.texts.contactUs") }}</router-link>
          </p>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
