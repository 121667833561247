<template lang="html">
  <div id="faqs-page">

    <section class="faqs-section" id="s-faq">
      <div class="container oversized-container">
        <h2 class="title-s3">{{ $t("pages.trust.titles.title1") }}</h2>

        <div class="mt-4 faqs-content">
          <div class="accordion" role="tablist">
            <b-card no-body>
              <b-card-header header-tag="header" class="p-1" role="tab">
                <a block v-b-toggle.accordion-1>
                  {{ $t("pages.trust.all.opt1Title") }}
                </a>
              </b-card-header>

              <b-collapse class="cll-content" id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <div v-html='$t("pages.trust.all.opt1Content")'></div>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" class="p-1" role="tab">
                <a block v-b-toggle.accordion-2>
                  {{ $t("pages.trust.all.opt2Title") }}
                </a>
              </b-card-header>

              <b-collapse class="cll-content" id="accordion-2" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <div v-html='$t("pages.trust.all.opt2Content")'></div>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" class="p-1" role="tab">
                <a block v-b-toggle.accordion-3>
                  {{ $t("pages.trust.all.opt3Title") }}
                </a>
              </b-card-header>

              <b-collapse class="cll-content" id="accordion-3" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <div v-html='$t("pages.trust.all.opt3Content")'></div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      faqs: [
        {
          q: 'Conoce algunos conceptos básicos del fideicomiso',
          qEng: 'Some basic concepts about trusts:',
          a: `<p>El Fideicomiso es la adaptación en México de un Trust, por lo que en esencia es un Trust mexicano. Es un contrato mediante el cual una persona física o moral, nacional o extranjera, afecta ciertos bienes o derechos para un fin lícito y determinado, en beneficio propio o de un tercero, encomendando la realización de dicho fin a una institución fiduciaria, expresamente autorizada para fungir como tal.</p>`,
          aEng: `<p>The Trust is the adaptation in Mexico of a Trust, so in essence it is a Mexican Trust. It is a contract through which a natural or legal person, national or foreign, affects certain assets or rights for a legal and specific purpose, for their own benefit or that of a third party, entrusting the realization of said purpose to a fiduciary institution, expressly authorized to serve as such.</p>`,
        },
        {
          q: '¿Qué es una fiduciaria?',
          qEng: 'What is a fiduciary?',
          a: `<p>Institución que recibe en propiedad fiduciaria dichos bienes, con la misión de cumplir las disposiciones o fines establecidos por el fideicomitente en el Contrato de Fideicomiso.<br /><br />A diferencia de otros sistemas fiduciarios en el mundo, la legislación mexicana reserva esta capacidad exclusivamente a las instituciones bancarias, y a otras instituciones financieras que estén facultadas para actuar como fiduciarias conforme a la legislación mexicana.</p>`,
          aEng: `<p>Institution that receives said assets as trust property, with the mission of complying with the provisions or purposes established by the trustor in the Trust Contract.<br /><br />Unlike other trust systems in the world, the Mexican legislation reserves this capacity exclusively to banking institutions, and to other financial institutions that are empowered to act as fiduciaries in accordance with Mexican legislation.</p>`,
        },
        {
          q: '¿Qué es un fideicomitente?',
          qEng: 'What is a trustor?',
          a: `<p>Persona que aporta al fideicomiso bienes o derechos de su propiedad, estableciendo condiciones de administración y distribución.</p>`,
          aEng: `<p>Person who contributes assets or rights of his or her property to the trust, establishing conditions of administration and distribution.</p>`
        },
        {
          q: '¿Qué es un fideicomisario?',
          qEng: 'What is a trustee?',
          a: `<p>Persona que recibe el beneficio del fideicomiso, puede ser el propio fideicomitente o un tercero.</p>`,
          aEng: `<p>Person who receives the benefit of the trust, it may be the trustor himself or a third party.</p>`
        },
        {
          q: 'Naturaleza del Patrimonio del Fideicomiso',
          qEng: 'Nature of the Trust Asset',
          a: `<p>
            En el momento en que se constituye un fideicomiso, el fideicomisario adquiere el derecho legal sobre los bienes fideicomitidos, que dejan de ser propiedad del fideicomitente. Esta transmisión se da aún en los casos en que el fideicomitente y el beneficiario sean la misma persona, ya que se considera que el fiduciario es el derechohabiente de dichos bienes durante la vigencia del fideicomiso. La legislación mexicana otorga a los bienes fideicomitidos una naturaleza autónoma; dichos bienes fideicomitidos son distintos a los bienes de las partes del fideicomiso y aunque estén bajo el control del fiduciario, dichos bienes no se consideran parte de su patrimonio dado que se destinan a un fin determinado conforme al fideicomiso.<br />
            <br />
            Ni el fiduciario ni el fideicomitente pueden realizar individualmente actos de dominio respecto de los bienes fideicomitidos, pudiendo actuar únicamente dentro de los límites que se hayan establecido en el correspondiente contrato de fideicomiso. A diferencia del concepto de propiedad civil en el que una parte tiene derecho a gozar y disponer libremente de sus bienes, en la titularidad fiduciaria tal derecho no existe ya que las partes no pueden disponer de la posesión y de los bienes en su beneficio.<br />
            <br />
            Los activos aportados a un fideicomiso conforman un patrimonio independiente el cual no se constituye como propiedad del fiduciario, por lo que si el fiduciario cae en bancarrota, dichos activos no se ven afectados, son asumidos por el gobierno y asignados a otro fiduciario.
            </p>
          `,
          aEng: `<p>
          At the time a trust is created, the trustee acquires the legal right over the trust assets, which cease to be the property of the settlor. This transmission occurs even in cases where the trustor and the beneficiary are the same person, since the trustee is considered to be the beneficiary of said assets during the validity of the trust. Mexican legislation grants trust assets an autonomous nature; Said trust assets are different from the assets of the parties to the trust and although they are under the control of the trustee, said assets are not considered part of his assets since they are intended for a specific purpose in accordance with the trust.<br />
           <br />
           Neither the trustee nor the settlor can individually carry out acts of ownership with respect to the trust assets, being able to act only within the limits established in the corresponding trust contract. Unlike the concept of civil property in which a party has the right to freely enjoy and dispose of its assets, in fiduciary ownership such right does not exist since the parties cannot dispose of the possession and the assets for their benefit. br />
           <br />
           The assets contributed to a trust form an independent patrimony which is not constituted as property of the trustee, so if the trustee falls into bankruptcy, said assets are not affected, they are assumed by the government and assigned to another trustee.
            </p>
          `
        },
        {
          q: '¿Qué es la Zona Restringida de acuerdo con la legislación mexicana?',
          qEng: 'What is the Restricted Zone according to Mexican legislation?',
          a: `<p>La zona restringida es la tierra que se encuentra a 100 kilómetros de cualquier frontera mexicana y 50 kilómetros de cualquier línea costera,  en dicha zona, los extranjeros no pueden adquirir bienes inmuebles a título oneroso, se requiere un fideicomiso para mantener la propiedad a favor del extranjero.</p>`,
          aEng: `<p>The restricted zone is the land that is 100 kilometers from any Mexican border and 50 kilometers from any coastline, in said zone, foreigners cannot acquire real estate for consideration, a trust is required to maintain the property in favor of the foreigner.</p>`
        },
        {
          q: '¿Cómo es el proceso para adquirir una propiedad a través de un Fideicomiso de Garantía con fines residenciales en la Zona Restringida en México?',
          qEng: 'What is the process to acquire a property through a Guarantee Trust for residential purposes in the Restricted Zone in Mexico?',
          a: `<p>
            El proceso de constitución suele llevarse a cabo designando al vendedor/ desarrolaldor como fideicomitente y transfiriendo irrevocablemente la titularidad de la propiedad al fideicomisario. A continuación, el fideicomisario mantiene la propiedad en beneficio del beneficiario. Normalmente, el beneficiario tendrá el derecho ilimitado de uso y goce de la propiedad, así como el beneficio de cualquier renta o producto de la venta, siempre y cuando los activos del fideicomiso se vendan, si así lo permite el fideicomiso.<br />
            <br />
            Las partes deben formalizar la constitución del fideicomiso mediante la transmisión de los bienes inmuebles ante un Notario Público mexicano.<br />
            <br />
            Al igual que cualquier otra transacción de bienes raíces, la creación de un fideicomiso de esta índole también requerirá la debida diligencia estándar sobre la propiedad, avalúos, inspecciones, búsquedas de títulos, la obtención de certificados de no gravamen, certificados de no deuda (sobre impuestos a la propiedad) y cualquier otro requisito habitual o estatutario bajo las leyes locales y estatales para el cierre de bienes raíces.<br />
            <br />
            El fideicomiso es un fideicomiso de garantía, por lo tanto, el Beneficiario puede designar en cualquier momento un préstamo o crédito para ser garantizado por los activos del fideicomiso y obtener liquidez de la propiedad.
            </p>
          `,
          aEng: `<p>
            The formation process is usually carried out by designating the seller/developer as the trustor and irrevocably transferring ownership of the property to the trustee. The trustee then holds the property for the benefit of the beneficiary. Normally, the beneficiary will have the unlimited right to use and enjoy the property, as well as the benefit of any income or proceeds from the sale, as long as the trust assets are sold, if permitted by the trust.<br />
            <br />
            The parties must formalize the constitution of the trust by transferring the real estate before a Mexican Notary Public.<br />
            <br />
            Like any other real estate transaction, creating such a trust will also require standard due diligence on the property, appraisals, inspections, title searches, obtaining certificates of no lien, certificates of no debt (on property taxes) and any other customary or statutory requirements under local and state laws for real estate closing.<br />
            <br />
            The trust is a guarantee trust, therefore, the Beneficiary can designate at any time a loan or credit to be guaranteed by the assets of the trust and obtain liquidity from the property.
            </p>
          `,
        },
        {
          q: '¿Puedo nombrar a un beneficiario substituto del Fideicomiso?',
          qEng: 'Can I name a substitute beneficiary of the Trust?',
          a: `<p>Sí, otra característica importante de un Fideicomiso de Garantía para fines residenciales en la Zona Restringida de México es que permiten al Beneficiario designar a un "Beneficiario Sustituto" al momento de la creación del fideicomiso. A la muerte del Beneficiario, el Beneficiario Sustituto lo sustituye como Beneficiario del Fideicomiso</p>`,
          aEng: `<p>Yes, another important feature of a Guarantee Trust for residential purposes in the Restricted Zone of Mexico is that they allow the Beneficiary to designate a "Substitute Beneficiary" at the time of the creation of the trust. Upon the death of the Beneficiary, the Substitute Beneficiary replaces him or her as Beneficiary of the Trust</p>`
        },
        {
          q: '¿Qué es una SOFOM?',
          qEng: 'What is a SOFOM?',
          a: `<p>Las Sociedades Financieras de Objeto Múltiple (Sofomes) son sociedades anónimas que cuentan con un registro vigente ante la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros (Condusef), y pueden ser reguladas y no reguladas.<br />
            <br />
            Tanto las Sofomes reguladas como las Sofomes no reguladas están sujetas a la inspección y vigilancia de la Comisión Nacional Bancaria y de Valores (CNBV), para verificar el cumplimiento de las disposiciones de lavado de dinero y financiamiento al terrorismo de conformidad con el Artículo 95 Bis de la Ley General de Organizaciones y Actividades Auxiliares de Crédito.
            </p>
          `,
          aEng: `<p>Multiple Purpose Financial Companies (Sofomes) are public limited companies that have a current registration with the National Commission for the Protection and Defense of Users of Financial Services (Condusef), and can be regulated and unregulated.<br />
            <br />
            Both regulated Sofomes and non-regulated Sofomes are subject to the inspection and surveillance of the National Banking and Securities Commission (CNBV), to verify compliance with the provisions of money laundering and terrorist financing in accordance with Article 95 Bis. of the General Law of Auxiliary Credit Organizations and Activities.
            </p>
          `
        },
      ],
    }
  }
}
</script>
