<template lang="html">
  <div id="text-page">
    <div class="container">
      <h1 class="mb-2 page-title">{{ $t("pages.terms.title") }}</h1>

      <!-- Esp -->
      <div v-if="$i18n.locale == 'es'">
        <p>
          El presente documento tiene como finalidad regular y determinar el uso y acceso a la plataforma web denominada LIONWARD (en lo sucesivo “La Plataforma”), propiedad de “LIONWARD” FICUNIAM S.A.P.I DE C.V. SOFOM E.N.R. de igual manera, el presente documento determinará los servicios y la forma en que se prestarán los mismos, por parte de la empresa mencionada anteriormente. Cualquier persona que acceda o haga uso de La Plataforma y en su momento, de los servicios ofrecidos, deberá regirse por lo establecido en el presente, sin excepción alguna y se sujeta a los derechos y obligaciones aquí pactadas, así como a las consecuencias que puedan derivar del mismo.
        </p>

        <br />
        <h5>OBJETO</h5>
        <p>
          El objeto del presente documento es regular el acceso y la utilización de La Plataforma, así como las condiciones de cada uno de los servicios o productos ofrecidos en la misma, los derechos y obligaciones de los usuarios, las consecuencias derivadas de no acatar el presente y demás disposiciones relativas a la contratación de los servicios.<br />
          <br />
          El usuario otorga su consentimiento al presente de manera tácita al momento de brindar la información de contacto requerida para obtener más información, consultoría y/o empezar el proceso de llevar a cabo la contratación de los servicios, por ende, a partir de ese momento se obliga a todo lo aquí estipulado.<br />
          <br />
          El acceso de manera completa a La Plataforma por parte del público es libre, sin embargo, para llevar a cabo la contratación de un servicio, se debe cumplir con los siguientes requisitos:
        </p>
        <ul>
          <li>Ser mayor de 18 años y adquirir los servicios por cuenta propia.</li>
          <li>Proporcionar nombre completo, correo electrónico y un mensaje con los servicios interesados.</li>
        </ul>

        <br />
        <p>
          En caso de que el Usuario proporcione cualquier dato falso, inexacto o simplemente el personal de la empresa considera que existen motivos fundados para negarle el acceso y/o registro a La Plataforma, así como a los servicios, cuentan con la autorización para hacerlo. Como se menciona en el rubro del presente, los servicios que se mencionan en el presente, ofrecidos por medio de La Plataforma serán en todo momento prestados de manera directa por la empresa “LIONWARD” FICUNIAM S.A.P.I. DE C.V. SOFOM E.N.R.
        </p>

        <br />
        <h5>DEFINICIONES</h5>
        <p>
          El presente documento de Términos y Condiciones de uso de La Plataforma, son las bases de cómo debe regirse el comportamiento y acceso a la misma por parte del Usuario, para la obtención de información o bien para llevar a cabo la contratación de los servicios.
        </p>
        <ul>
          <li>
            Usuario/Cliente: Persona física exclusivamente, que accede a La Plataforma y brinda la información que se le solicita para obtener más información y/o adquirir los servicios ofrecidos en la misma.
          </li>
          <li>
            Plataforma: Es el dominio LION-WARD en su totalidad y los sitios relacionados al mismo, mismo que operan y son propiedad de “LIONWARD” FICUNIAM S.A.P.I. DE C.V. SOFOM E.N.R. incluyendo todo el contenido que se muestra en la misma.
          </li>
          <li>
            Servicios: Se trata de los servicios de diseño, constitución y administración de Fideicomisos de Garantía y Fideicomisos de Garantía en Zona Restringida para Fines Residenciales y Fideicomisos de Garantía como Medio de Pago, que presta la empresa “LIONWARD” FICUNIAM S.A.P.I. DE C.V. SOFOM E.N.R.. por medio de La Plataforma. Características que se detallan en el presente.
          </li>
          <li>
            Las empresas: Se refiere a la sociedad “LIONWARD” FICUNIAM S.A.P.I. DE C.V. SOFOM E.N.R., quien actúa en el presente y presta los servicios de manera directa.
          </li>
        </ul>

        <br />
        <h5>SERVICIOS Y CONTENIDOS</h5>
        <p>
          Los servicios que ofrece de manera directa La Empresa son: diseño, constitución y administración de Fideicomisos de Garantía y Fideicomisos de Garantía en Zona Restringida para Fines Residenciales y Fideicomisos de Garantía como Medio de Pago.<br />
          <br />
          Adicional a los servicios anteriormente mencionados, los cuales constituyen un listado de carácter enunciativo más no limitativo, por lo que podrán brindar todos los servicios y ofrecer todos los productos que se publiquen en la Plataforma.
        </p>

        <br />
        <h5>QUEJAS O ACLARACIONES</h5>
        <p>
          En caso que el Cliente tenga algún tipo de queja, reclamación o aclaración sobre los servicios contratados, deberá enviar un correo electrónico a la siguiente dirección: support@lion-ward.com.
        </p>

        <br />
        <h5>DECLARACIONES DE USO</h5>
        <p>
          El Usuario, por medio de la aceptación del presente y al proporcionar la información solicitada y adquirir servicios manifiesta:
        </p>
        <ul>
          <li>
            Tener plena capacidad de goce y ejercicio para adquirir los servicios que desee.
          </li>
          <li>
            Deslinda por medio del presente de cualquier tipo de responsabilidad legal a “LIONWARD” FICUNIAM S.A.P.I. DE C.V. SOFOM E.N.R. por los supuestos mencionados en el presente o cualquier otro que pudiera surgir que no sea competencia de este último.
          </li>
          <li>
            No hará mal uso de los servicios adquiridos a La Empresa y/o de La Plataforma en perjuicio de este último.
          </li>
        </ul>

        <br />
        <h5>OBLIGACIONES DEL USUARIO</h5>
        <p>
          Se compromete de manera general a usar de manera correcta y responsable su perfil dentro de La Plataforma, sujeto a las legislaciones vigentes aplicables en territorio mexicano; en caso de violación a cualquier norma El Usuario podrá ser consignado a las autoridades correspondientes o bien acusado por la violación cometida.<br />
          <br />
          Se obliga de manera enunciativa mas no limitativa a lo siguiente:
        </p>
        <ul>
          <li>
            No utilizar La Plataforma para fines ilegales.
          </li>
          <li>
            No infringir los derechos de propiedad intelectual pertenecientes a “LIONWARD” FICUNIAM S.A.P.I. DE C.V. SOFOM E.N.R. o cualquier prestador de servicios que opere por medio de La Plataforma.
          </li>
          <li>
            No utilizar La Plataforma de forma fraudulenta.
          </li>
        </ul>

        <br />
        <p>
          Cualquier actividad que sea considerada en contra de las normas de uso y de las buenas costumbres podrá ser sancionada por parte de las autoridades competentes, siendo remitido por el personal de “LIONWARD” FICUNIAM S.A.P.I. DE C.V. SOFOM E.N.R.  o bien cualquier trabajador de las instalaciones donde se presten los servicios.<br />
          <br />
          Cualquier Usuario podrá denunciar actos ilegales cometidos por cualquier persona, Usuario, trabajador, etc. dentro de las instalaciones o bien en La Plataforma.
        </p>

        <br />
        <h5>PRIVACIDAD</h5>
        <p>
          Para revisar nuestro Aviso de privacidad consulta la página web de LIONWARD en www.lion-ward.com
        </p>
      </div>
      <!--  -->

      <!-- Eng -->
      <div v-if="$i18n.locale == 'en'">
        <p>
          The purpose of this document is to regulate and determine the use and access to the web platform called LIONWARD (hereinafter "The Platform"), owned by "LIONWARD" FICUNIAM S.A.P.I DE C.V. SOFOM E.N.R. Likewise, this document will determine the services and how they will be provided by the company mentioned above. Any person who accesses or makes use of the Platform and at the time, the services offered, shall be governed by the provisions herein, without exception and is subject to the rights and obligations agreed herein, as well as the consequences that may arise therefrom.
        </p>

        <br />
        <h5>PURPOSE</h5>
        <p>
          The purpose of this document is to regulate the access and use of the Platform, as well as the conditions of each of the services or products offered in it, the rights and obligations of users, the consequences of not abiding by this and other provisions relating to the procurement of services.<br />
          <br />
          The user tacitly grants their consent to the present at the moment of providing the required contact information to obtain more information, consultancy and/or start the process of contracting the services, therefore, from that moment on, the user is bound to everything stipulated herein.<br />
          <br />
          Access to the Platform by the public is free, however, to carry out the contracting of a service, the following requirements must be met:
        </p>
        <ul>
          <li>Be 18 years of age or older and purchase the services on your own account.Provide full name, email and a message with the services you are interested in.</li>
        </ul>

        <br />
        <p>
          In the event that the user provides any false, inaccurate or simply the company's staff believes that there are grounds to deny access and / or registration to the Platform and services, have the authorization to do so. As mentioned in the heading of the present, the services mentioned herein, offered through the Platform will be at all times provided directly by the company "LIONWARD" FICUNIAM S.A.P.I. DE C.V. SOFOM E.N.R.
        </p>

        <br />
        <h5>DEFINITIONS</h5>
        <p>
          This Terms and Conditions of Use of the Platform are the basis of how to govern the behavior and access to it by the User, to obtain information or to carry out the procurement of services.
        </p>
        <ul>
          <li>
            User/Client: Natural person exclusively, who accesses The Platform and provides the information requested to obtain more information and/or acquire the services offered therein.
          </li>
          <li>
            Platform: It is the domain LION-WARD in its entirety and sites related to it, same that operate and are owned by "LIONWARD" FICUNIAM S.A.P.I. DE C.V. SOFOM E.N.R. including all content displayed on it.
          </li>
          <li>
            Services: These are the design, incorporation and administration services of Guarantee Trusts and Guarantee Trusts in Restricted Zone for Residential Purposes and Guarantee Trusts as a Means of Payment provided by the company "LIONWARD" FICUNIAM S.A.P.I. DE C.V. SOFOM E.N.R. through the Platform. Characteristics that are detailed herein.
          </li>
          <li>
            Companies: Refers to the company "LIONWARD"FICUNIAM S.A.P.I. DE C.V. SOFOM E.N.R., who acts in the present and provides services directly.
          </li>
        </ul>

        <br />
        <h5>SERVICES AND CONTENT</h5>
        <p>
          The services offered directly by The Company are: design, incorporation and administration of Guarantee Trusts and Guarantee Trusts in Restricted Zone for Residential Purposes and Guarantee Trusts as a Means of Payment.<br />
          <br />
          In addition to the aforementioned services, which are a list of enunciative but not limited, so they can provide all services and offer all products that are published on the Platform.
        </p>

        <br />
        <h5>COMPLAINTS AND CLARIFICATIONS</h5>
        <p>
          In case the Client has any kind of complaint, claim or clarification about the contracted services, the Client should send an e-mail to the following address: support@lion-ward.com.
        </p>

        <br />
        <h5>DECLARATIONS OF USE</h5>
        <p>
          The User, by means of the acceptance of the present and by providing the requested information and acquiring services, declares:
        </p>
        <ul>
          <li>
            To have full capacity to enforce and enjoy the services he/she desires to acquire.
          </li>
          <li>
            The User hereby releases LIONWARD" FICUNIAM S.A.P.I. DE C.V. SOFOM E.N.R. from any kind of legal responsibility for the assumptions mentioned herein or any other that may arise that is not within the competence of the latter.
          </li>
          <li>
            You will not misuse the services purchased from the Company and/or the Platform to the detriment of the latter.
          </li>
        </ul>

        <br />
        <h5>USER OBLIGATIONS</h5>
        <p>
          Undertakes to use in a general manner correctly and responsibly their profile within the Platform, subject to current legislation applicable in Mexican territory, in case of violation of any rule The User may be consigned to the appropriate authorities or charged for the violation committed.<br />
          <br />
          It is obliged but not limited to the following:
        </p>
        <ul>
          <li>
            Do not use the Platform for illegal purposes.
          </li>
          <li>
            Do not infringe the intellectual property rights belonging to "LIONWARD"  FICUNIAM S.A.P.I. DE C.V. SOFOM E.N.R. or any service provider operating through the Platform.
          </li>
          <li>
            Do not use the Platform fraudulently.
          </li>
        </ul>

        <br />
        <p>
          Any activity that is considered against the rules of use and good customs may be punished by the competent authorities, being referred by the staff of "LIONWARD" FICUNIAM S.A.P.I. DE C.V. SOFOM E.N.R. or any worker of the facilities where services are provided.<br />
          <br />
          Any User may report illegal acts committed by any person, User, worker, etc. within the facilities or on the Platform.
        </p>

        <br />
        <h5>PRIVACY</h5>
        <p>
          Please review our Privacy Notice on the LIONWARD website at www.lion-ward.com
        </p>
      </div>
      <!--  -->
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
    }
  },
}
</script>
