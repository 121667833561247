<template lang="html">
  <header id="header">
    <div class="header-content">
      <b-navbar toggleable="lg" type="dark" variant="dark">
        <div class="container oversized-container">
          <b-navbar-brand to="/">
            <img src="public/images/logo-white-noslogan.svg">
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item class="simple-item" to="/">{{ $t("shared.links.home") }}</b-nav-item>
              <li class="nav-item simple-item">
                <router-link class="nav-link" to="/nosotros">{{ $t("shared.links.aboutUs") }}</router-link>
              </li>
              <b-nav-item class="simple-item" to="/servicios">{{ $t("shared.links.services") }}</b-nav-item>
              <b-nav-item class="simple-item" to="/contacto">{{ $t("shared.links.contact") }}</b-nav-item>
              <b-nav-item class="simple-item" to="/faqs">{{ $t("shared.links.faq") }}</b-nav-item>

              <b-nav-item-dropdown class="dropdown-lang" right>
                <!-- Using 'button-content' slot -->
                <template #button-content>
                  <span class="lang" v-if="$i18n.locale == 'es'">
                    <span class="txt">ESP</span>
                    <div class="flag esp"></div>
                  </span>
                  <span class="lang" v-if="$i18n.locale == 'en'">
                    <span class="txt">ENG</span>
                    <div class="flag eng"></div>
                  </span>
                </template>
                <li>
                  <a class="dropdown-item" v-if="$i18n.locale != 'es'" @click="$root.changeLocale('es')">
                    <span>ESP</span> <div class="flag esp"></div>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" v-if="$i18n.locale != 'en'" @click="$root.changeLocale('en')">
                    <span>ENG</span> <div class="flag eng"></div>
                  </a>
                </li>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>
  </header>
</template>

<script>
export default {
}
</script>
